@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face { 
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("./fonts/Inter-Regular.woff2") format("woff2");
}

body {
    padding: 0;
    margin: 0;
    background-color: #191B1F;
    color: #FFFFFF;
    font-family: "-apple-system", "BlinkMacSystemFont", "Inter", "Helvetica", "Tahoma", "Arial";
}

input {
    background-color: transparent;
    outline: none;
}